import React from 'react';
import shopRequest from '@/requests/shop';
import { Icon } from '@/components/widgets/icon';
import { uiPrompt } from '@/avatars';
import AutoEllipsisText from '@/components/widgets/autoEllipsisText';
import css from './style.less';

type DDProps = {
  buyerOpenUid: string;
  icon?: React.ReactNode;
};

const openChatTarget = 'maia-dd-chat';

export const DDOpenChat = (props: DDProps) => {
  const { icon, buyerOpenUid } = props;

  // 记录是否按住了ctrl/cmd键
  let isCtrlOrCmdPressed = false;
  document.addEventListener('keydown', e => {
    if (e.key === 'Meta' || e.key === 'Control') {
      isCtrlOrCmdPressed = true;
    }
  });
  document.addEventListener('keyup', e => {
    if (e.key === 'Meta' || e.key === 'Control') {
      isCtrlOrCmdPressed = false;
    }
  });

  const getOpenChatTargetNumber = (): number => {
    const i = +(localStorage.getItem(openChatTarget) ?? '0');
    if (!isCtrlOrCmdPressed) {
      return i;
    }
    const num = Number.isSafeInteger(i + 1) ? i + 1 : 0;
    localStorage.setItem(openChatTarget, `${num}`);
    return num;
  };

  const openChat = async (): Promise<void> => {
    try {
      const res = await shopRequest.getOpenDialogInfo(buyerOpenUid);

      if (!res) {
        return;
      }
      const num = getOpenChatTargetNumber();
      const { taobaoTid, platformBuyerId } = res;
      let url = 'https://im.jinritemai.com/pc_seller_v2/main/workspace?';

      if (taobaoTid) {
        url += `fromOrder=${taobaoTid}`;
      } else if (platformBuyerId) {
        url += `otherSideId=${platformBuyerId}`;
      }
      window.open(url, `${openChatTarget}#${num}`);
    } catch (error) {
      uiPrompt.current.error(error);
    }
  };

  return (
    <span className={css.ddBuyer}>
      <AutoEllipsisText text={buyerOpenUid} />
      <span onClick={openChat}>
        {icon || <Icon type="feige" className={css.ddIcon} />}
      </span>
    </span>
  );
};
