// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_ddBuyer_F8ex4 {\n  display: flex;\n  align-items: center;\n}\n.style_ddBuyer_F8ex4 .style_ddIcon_zGWtY {\n  color: #1e9eff;\n  font-size: 20px;\n  cursor: pointer;\n  margin-left: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/ddOpenChat/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AAHA;EAKI,cAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;AACJ","sourcesContent":[".ddBuyer {\n  display: flex;\n  align-items: center;\n\n  .ddIcon {\n    color: #1e9eff;\n    font-size: 20px;\n    cursor: pointer;\n    margin-left: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ddBuyer": "style_ddBuyer_F8ex4",
	"ddIcon": "style_ddIcon_zGWtY"
};
export default ___CSS_LOADER_EXPORT___;
