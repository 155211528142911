import React, { memo, PropsWithChildren, ReactNode } from 'react';
import { Platform } from '@/types/platform';
import { usePlatform } from '@/hooks/usePlatform';

type PlatformWrapperProps = {
  component?: Partial<Record<Platform, ReactNode>>;
};

export const PlatformWrapper = memo(
  (props: PropsWithChildren<PlatformWrapperProps>) => {
    const { component } = props;
    const { platform } = usePlatform();

    if (component && React.isValidElement(component[platform])) {
      return component[platform];
    }

    return null;
  }
);

export const DisplayWithPlatform = memo<
  PropsWithChildren<{
    displayPlatform?: Platform[];
    hiddenPlatform?: Platform[];
  }>
>(props => {
  const { children, displayPlatform, hiddenPlatform } = props || {};
  const { platform } = usePlatform();

  if (hiddenPlatform?.includes(platform)) {
    return null;
  }

  if (!displayPlatform?.length || displayPlatform.includes(platform)) {
    return <>{children}</>;
  }

  return null;
});
