import { RemindType } from '@/types/remindType';
import { MainStrategyConfig } from './type';
import { getPlatform } from '@/services/platform';

const { isTB } = getPlatform();

export const unDisturbMinute = [1, 2, 3, 5, 10, 20, 30, 40, 50, 60]; // 免打扰-分钟
export const unDisturbHour = [1, 2, 3, 6, 10, 12, 24]; // 免打扰-小时
export const unDisturbDay = [1, 2, 3, 5, 7, 10, 15, 30]; // 免打扰-天
export const times = [
  { key: 1, value: '1' },
  { key: 2, value: '2' },
  { key: 3, value: '3' }
]; // 免打扰-次数
export const moreTimes = [
  { key: 4, value: '4' },
  { key: 5, value: '5' }
];
export const replyTimeOutTimes = [
  { key: 5, value: '5' },
  { key: 10, value: '10' },
  { key: 9999, value: '无限' }
];
export const buyerPaymentHours = [1, 2, 3, 5, 10, 12]; // 买家多少小时内有付款订单
export const buyerPaymentDays = [1, 2, 3, 5, 10, 15, 30]; // 买家多少天内有付款订单
export const hasBuyItemsDays = [1, 2, 3, 4, 5, 7, 10, 15, 20, 30, 60]; // 买家已购买商品多少天不推荐
export const latestPaidTradeFilterMonths = [1, 3, 6, 12]; // 非首次付款买家月数

export const remindTypeDecs: Partial<Record<RemindType, string>> = {
  REMIND_BUY: '催拍',
  REMIND_PAY: '催付',
  REMIND_FINAL_PAY: '催尾款',
  PAYMENT_PUSH: '付款关怀',
  FRONT_PAID_PAYMENT_PUSH: '付定金关怀',
  FINAL_PAID_PAYMENT_PUSH: '付尾款关怀',
  CONSIGN_PUSH: '发货提醒',
  LOGISTIC_STAGNATE_PUSH: '物流停滞提醒',
  DELIVERY_PUSH: '派送提醒',
  SIGN_PUSH: '签收提醒',
  RECEIVED_PUSH: '确认收货关怀',
  APPLY_REFUND_PUSH: '申请退款关怀',
  GUIDE_RETURN_GOODS: '退货引导',
  URGE_RETURN_GOODS_PUSH: '催买家退货',
  REFUND_SUCCESS_PUSH: '退款成功',
  APPLY_EXCHANGE_PUSH: '换货关怀',
  TRADE_NOPAID_CLOSED_PUSH: '订单取消关怀',
  REPLY_TIMEOUT_PUSH: '等待超时回复',
  TRANSFER_WORD_PUSH: '转接话术',
  INVITE_RATE: '服务邀评',
  AFTER_CONSULT_ITEM_RECOMMEND: '咨询后推荐',
  AFTER_PAID_ITEM_RECOMMEND: '付款后推荐'
};

export const pushDecsRemindType = [
  'REMIND_FINAL_PAY',
  'PAYMENT_PUSH',
  'FRONT_PAID_PAYMENT_PUSH',
  'FINAL_PAID_PAYMENT_PUSH',
  'SIGN_PUSH',
  'RECEIVED_PUSH',
  'APPLY_REFUND_PUSH',
  'GUIDE_RETURN_GOODS',
  'URGE_RETURN_GOODS_PUSH',
  'REFUND_SUCCESS_PUSH',
  'CONSIGN_PUSH',
  'LOGISTIC_STAGNATE_PUSH',
  'DELIVERY_PUSH',
  'REPLY_TIMEOUT_PUSH',
  'TRADE_NOPAID_CLOSED_PUSH'
];

export const returnStatusesNotPushMap = {
  PAYMENT_PUSH: '付款的订单有退换状态时，相关订单将不推送此任务',
  FRONT_PAID_PAYMENT_PUSH: '付款的订单有退换状态时，相关订单将不推送此任务',
  FINAL_PAID_PAYMENT_PUSH: '付款的订单有退换状态时，相关订单将不推送此任务',
  SIGN_PUSH: '签收的商品有退换状态时，将不推送此任务',
  RECEIVED_PUSH: '确认收货的订单有退换状态时，相关订单将不推送此任务',
  CONSIGN_PUSH: '发货的商品有退换状态时，将不推送此任务',
  DELIVERY_PUSH: '派送的商品有退换状态时，将不推送此任务',
  LOGISTIC_STAGNATE_PUSH: '停滞的商品有退换状态时，将不推送此任务',
  AFTER_PAID_ITEM_RECOMMEND: '付款的订单有退换状态时，相关订单将不推送此任务'
};

// 是否订单备注
export enum showOrderRemark {
  // 所有跟单助手
  REMIND_BUY,
  REMIND_PAY,
  REMIND_FINAL_PAY,
  PAYMENT_PUSH,
  FRONT_PAID_PAYMENT_PUSH,
  FINAL_PAID_PAYMENT_PUSH,
  CONSIGN_PUSH,
  LOGISTIC_STAGNATE_PUSH,
  DELIVERY_PUSH,
  SIGN_PUSH,
  RECEIVED_PUSH,
  APPLY_REFUND_PUSH,
  GUIDE_RETURN_GOODS,
  URGE_RETURN_GOODS_PUSH,
  REFUND_SUCCESS_PUSH,
  APPLY_EXCHANGE_PUSH,
  TRADE_NOPAID_CLOSED_PUSH,
  AFTER_PAID_ITEM_RECOMMEND // 商品推荐
}

const commonMainStrategyConfig: MainStrategyConfig[] = [
  'remindLimit',
  'filterItem',
  'filterReceiverAddress',
  'filterFinalAccount',
  'filterChatMessage',
  'orderRemark'
];
const commonMainStrategyConfigB: MainStrategyConfig[] = [
  'remindLimit',
  'filterItem',
  'filterFinalAccount',
  'filterChatMessage',
  'orderRemark'
];

export const mainStrategy = {
  remindLimit: ['REMIND_BUY', 'REMIND_PAY', 'REMIND_FIND_PAY']
};

export const mainStrategyConfig: Partial<
  Record<RemindType, MainStrategyConfig[]>
> = {
  REMIND_BUY: [
    'remindLimit',
    'afterSale',
    'filterItem',
    'filterFinalAccount',
    'filterChatMessage'
  ],
  REMIND_PAY: [
    'remindLimit',
    'filterTrade',
    'filterItem',
    'filterReceiverAddress',
    'filterFinalAccount',
    'filterChatMessage',
    'orderRemark'
  ],
  REMIND_FINAL_PAY: commonMainStrategyConfig,
  PAYMENT_PUSH: [...commonMainStrategyConfig, 'latestPaidTradeFilter'],
  FRONT_PAID_PAYMENT_PUSH: [
    ...commonMainStrategyConfig,
    'latestPaidTradeFilter'
  ],
  FINAL_PAID_PAYMENT_PUSH: [
    ...commonMainStrategyConfig,
    'latestPaidTradeFilter'
  ],
  CONSIGN_PUSH: commonMainStrategyConfig,
  LOGISTIC_STAGNATE_PUSH: commonMainStrategyConfig,
  DELIVERY_PUSH: commonMainStrategyConfig,
  SIGN_PUSH: commonMainStrategyConfig,
  RECEIVED_PUSH: commonMainStrategyConfig,
  APPLY_REFUND_PUSH: isTB
    ? [...commonMainStrategyConfig, 'afterSignMsgFilter']
    : [...commonMainStrategyConfig],
  URGE_RETURN_GOODS_PUSH: commonMainStrategyConfig,
  GUIDE_RETURN_GOODS: commonMainStrategyConfig,
  REFUND_SUCCESS_PUSH: commonMainStrategyConfigB,
  APPLY_EXCHANGE_PUSH: commonMainStrategyConfig,
  TRADE_NOPAID_CLOSED_PUSH: [
    'remindLimit',
    'filterTrade',
    'filterItem',
    'filterReceiverAddress',
    'filterFinalAccount',
    'filterChatMessage',
    'orderRemark'
  ],
  REPLY_TIMEOUT_PUSH: [
    'remindLimit',
    'afterSale',
    'filterFinalAccount',
    'filterChatMessage'
  ],
  INVITE_RATE: [
    'inviteRate',
    'tradeStatus',
    'filterItem',
    'filterFinalAccount',
    'filterChatMessage',
    'filterMsgCount'
  ],
  AFTER_CONSULT_ITEM_RECOMMEND: [
    'remindLimit',
    'afterSale',
    'filterItem',
    'filterPurchased',
    'filterFinalAccount',
    'filterChatMessage'
  ],
  AFTER_PAID_ITEM_RECOMMEND: [
    'remindLimit',
    'filterItem',
    'filterReceiverAddress',
    'filterPurchased',
    'filterFinalAccount',
    'filterChatMessage',
    'orderRemark'
  ]
};
