import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  memo
} from 'react';
import { Checkbox, CheckboxGroup } from '@/components/widgets/checkbox';
import { Space } from '@/components/widgets/space';
import { FlagsWithPlatform } from '@/components/works/flagWithPlatform';
import userConfigRequest from '@/requests/userConfig';
import { SellerFlag } from '@/requests/userConfig/type';

type SellerFlagsProps = {
  value?: number[];
  onChange?: (value: number[]) => void;
};

const FlagsContext = createContext<number[]>([]);

const Flag = (props: { flag: SellerFlag }) => {
  const filterFlags = useContext(FlagsContext);
  const {
    flag: { flag, code }
  } = props;

  return (
    <Checkbox checked={filterFlags.includes(flag)} value={flag}>
      <FlagsWithPlatform color={code.toLowerCase()} />
    </Checkbox>
  );
};

export const SellerFlags = memo((props: SellerFlagsProps) => {
  const { value = [], onChange } = props;
  const [sellerFlags, setSellerFlags] = useState<SellerFlag[]>([]);

  const fetchFlags = async () => {
    const flags = await userConfigRequest.sellerFlagsWithGrey();

    setSellerFlags(flags);
  };

  useEffect(() => {
    fetchFlags();
  }, []);

  return (
    <FlagsContext.Provider value={value}>
      <CheckboxGroup value={value} onChange={v => onChange?.(v as number[])}>
        <Space size={24}>
          {sellerFlags.map(flag => (
            <Flag key={flag.flag} flag={flag} />
          ))}
        </Space>
      </CheckboxGroup>
    </FlagsContext.Provider>
  );
});
