import React, { memo, useState } from 'react';
import classNames from 'classnames';
import MiniImage from '@/components/works/miniImage';
import { Paragraph } from '@/components/widgets/typography';
import { Tooltip } from '@/components/widgets/tooltip';
import { copy } from '@/utils/dxUtils';
import { SkuToolTip } from '../skuTooltipInfo';
import css from './style.less';
import { approveStatusMap } from '../../goodsSelectModal/constants';
import { SpuItemDto } from '@/types/esItem';
import { usePlatform } from '@/hooks/usePlatform';
import { openGoodsDetail } from '@/services/openGoodsDetail';

type SpuItemProps = {
  type: 'wait' | 'selected';
  item: SpuItemDto;
  taobaoSkuIdSet: Set<string | undefined>;
};

export const SpuItem = memo((props: SpuItemProps) => {
  const { item, taobaoSkuIdSet, type } = props;
  const {
    picUrl,
    title,
    outerIid,
    approveStatus,
    skuList = [],
    taobaoIid,
    url
  } = item;
  const { isJD } = usePlatform();

  const selectSkusForSpu = skuList?.filter(v =>
    taobaoSkuIdSet.has(v.taobaoSkuId)
  );

  const toJdDetail = (e: React.MouseEvent | undefined) => {
    e?.stopPropagation();
    if (isJD) {
      window.open(url);
    } else {
      openGoodsDetail(taobaoIid);
    }
  };

  const copyID = (e: React.MouseEvent) => {
    e.stopPropagation();
    copy(taobaoIid);
  };

  return (
    <div className={classNames(css.option)}>
      <div className={css.imgBox}>
        <MiniImage
          lazyLoad
          size={48}
          src={picUrl}
          className={css.img}
          onClick={toJdDetail}
        />
        <div className={css.approveStatusMask}>
          {approveStatusMap[approveStatus]}
        </div>
      </div>
      <div className={css.texts}>
        <Paragraph
          className={classNames(css.name, css.nameLink)}
          ellipsis={{ rows: 2, tooltip: title }}
          onClick={toJdDetail}
        >
          {title}
        </Paragraph>
        <div className={css.code}>
          <Tooltip title={`ID：${taobaoIid}`}>
            <span className={css.iid} onClick={copyID}>
              ID
            </span>
          </Tooltip>
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              gap: '12px'
            }}
          >
            {outerIid ? (
              <Paragraph
                style={{ color: '#8c8c8c', minWidth: '64px' }}
                ellipsis={{ rows: 1, tooltip: outerIid }}
              >
                {`商品编码 ${outerIid}`}
              </Paragraph>
            ) : null}
          </div>
          {selectSkusForSpu.length ? (
            <Tooltip
              title={<SkuToolTip itemSkus={selectSkusForSpu} />}
              color="#fff"
              placement={type === 'selected' ? 'left' : 'right'}
              overlayInnerStyle={{ padding: '0px' }}
            >
              <span style={{ fontSize: '14px', marginRight: '24px' }}>
                {selectSkusForSpu ? (
                  <span style={{ color: '#ffbc87' }}>
                    已选{selectSkusForSpu.length}个SKU
                  </span>
                ) : null}
              </span>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
});
