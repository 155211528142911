import { Http, http } from '@/libs/http';
import {
  AccountListDto,
  AreaWebDto,
  ItemCategoryDto,
  RefundReasonDto,
  SellerCategoryDto,
  TaskIdParams,
  TaskStatusDto,
  ValidateRemindStrategyContentDto,
  ValidateRemindStrategyContentParams,
  StrategyNumDto,
  TaobaoHistoryDataSyncProgressWebDto,
  RemindTimesConfig,
  UpdateTimeConfigParams,
  CheckTimeConfigParams,
  CheckForModifyTypeDto,
  RemindSubStrategyIdAndNameWebDto,
  SubAccountLoginStatusParams,
  SubAccountLoginWebDto,
  EnterpriseSubAccountWebQuery,
  SubAccountWebDto,
  SubAccountTraceOrderUpdateWebCmd,
  SubAccountTraceOrderSelfModifiedAllowedUpdateWebCmd,
  SubAccountSendSpeedUpdateWebCmd,
  MarketingLimitNumAndSendedNum,
  SubAccountTrackOrderLimitDto,
  BaseInfoDto,
  SubAccountPermissionBatchUpdateCmd,
  SubAccountPermissionBatchUpdateResult,
  ShopNickConfigDto,
  SubAccountPersonalNickUpdateWebCmd,
  OpenDialogInfoDto
} from './type';
import { RemindType } from '@/types/remindType';
import { PageDetail } from '@/types/pageDetail';

export class ShopRequest {
  private http: Http = http('/api/shop');

  getAccountList(): Promise<AccountListDto[]> {
    return this.http.path('sub-account-list').get<AccountListDto[]>();
  }

  fetchShopPermission(): Promise<string[]> {
    return this.http.path('shop-permission').get<string[]>();
  }

  fetchAreaList(): Promise<AreaWebDto[]> {
    return this.http.path('area/list').get<AreaWebDto[]>();
  }

  fetchRefundReason(): Promise<RefundReasonDto[]> {
    return this.http.path('refund-reason/list').get<RefundReasonDto[]>();
  }

  exchangeReasonList(): Promise<RefundReasonDto[]> {
    return this.http.path('exchange-reason/list').get();
  }

  // 获取店铺分类
  fetchSellerCatList(): Promise<SellerCategoryDto[]> {
    return this.http.path('seller-cat/list-v2').get<SellerCategoryDto[]>();
  }

  // 获取商品分类
  fetchItemCatList(): Promise<ItemCategoryDto[]> {
    return this.http.path('item-cat/list').get<ItemCategoryDto[]>();
  }

  taskStatus(params: TaskIdParams): Promise<TaskStatusDto> {
    return this.http
      .path('/task-status')
      .setRequestParams(params)
      .get<TaskStatusDto>();
  }

  validateRemindStrategyContent(
    params: ValidateRemindStrategyContentParams[]
  ): Promise<ValidateRemindStrategyContentDto> {
    return this.http
      .path('remind-strategy-content/validate-v2')
      .setRequestBody(params)
      .post<ValidateRemindStrategyContentDto>();
  }

  // 推送次数信息接口
  getStrategyNum(remindTimesConfigId: number): Promise<StrategyNumDto> {
    return this.http
      .path('remind-times-config/strategy-num')
      .setRequestParams({ remindTimesConfigId })
      .get<StrategyNumDto>();
  }

  getRemindTimesConfigList(
    remindType: RemindType
  ): Promise<RemindTimesConfig[]> {
    return this.http
      .path('remind-times-config/list')
      .setRequestParams({ remindType })
      .get<RemindTimesConfig[]>();
  }

  updateRemindTimesConfig(params: UpdateTimeConfigParams): Promise<boolean> {
    return this.http
      .path('remind-times-config/update')
      .setRequestBody(params)
      .post<boolean>();
  }

  checkRemindTimesConfig(
    params: CheckTimeConfigParams
  ): Promise<CheckForModifyTypeDto> {
    return this.http
      .path('remind-times-config/check')
      .setRequestBody(params)
      .post<CheckForModifyTypeDto>();
  }

  // 会员营销数据同步接口
  checkTaobaoHistoryDataSynchronizing(): Promise<TaobaoHistoryDataSyncProgressWebDto> {
    return this.http.path('check-taobao-history-data-synchronizing').get();
  }

  visitShopConfig(): Promise<{
    visitorNick: string;
    athenaAdRequestParams: { subNick: string; sellerNick: string };
  }> {
    return this.http.path('visit-shop-config').post();
  }

  // 根据推送次数查询任务名称
  getStrategyNameList(
    remindType: RemindType,
    remindTimesType?: string | null
  ): Promise<RemindSubStrategyIdAndNameWebDto[]> {
    return this.http
      .path('remind-stats/list-strategy-names')
      .setRequestParams({ remindType, remindTimesType })
      .get();
  }

  // 子账号在线情况
  subAccountLoginStatus(
    params: SubAccountLoginStatusParams
  ): Promise<PageDetail<SubAccountLoginWebDto>> {
    return this.http
      .path('/sub-account/login-status')
      .setRequestBody(params)
      .post();
  }

  subAccountManager(
    params: EnterpriseSubAccountWebQuery
  ): Promise<SubAccountWebDto[]> {
    return this.http.path('/sub-account/list').setRequestBody(params).post();
  }

  updateTraceOrderEnable(
    params: SubAccountTraceOrderUpdateWebCmd
  ): Promise<void> {
    return this.http
      .path('/sub-account/update-trace-order-enable')
      .setRequestBody(params)
      .post();
  }

  updateTraceOrderEnableModifyAllowed(
    params: SubAccountTraceOrderSelfModifiedAllowedUpdateWebCmd
  ): Promise<void> {
    return this.http
      .path('/sub-account/update-trace-order-enable-modify-allowed')
      .setRequestBody(params)
      .post();
  }

  updateSendMessageSpeed(
    params: SubAccountSendSpeedUpdateWebCmd
  ): Promise<void> {
    return this.http
      .path('/sub-account/update-send-message-speed')
      .setRequestBody(params)
      .post();
  }

  batchUpdatePermission(
    params: SubAccountPermissionBatchUpdateCmd
  ): Promise<SubAccountPermissionBatchUpdateResult> {
    return this.http
      .path('/sub-account/batch-update-permission')
      .setRequestBody(params)
      .post();
  }

  // 查询店铺版本信息
  baseInfo(): Promise<BaseInfoDto> {
    return this.http.path('/base-info').post();
  }

  // 查询店铺发送量配置
  marketingSendNumInfo(): Promise<MarketingLimitNumAndSendedNum> {
    return this.http.path('/marketing-send-num-info').post();
  }

  // 查询店铺客服跟单数量配置
  subAccountTrackOrderInfo(): Promise<SubAccountTrackOrderLimitDto> {
    return this.http.path('/sub-account-track-order-info').post();
  }

  //  获取店铺昵称配置
  acquireShopNickConfig(): Promise<ShopNickConfigDto> {
    return this.http.path('/nick-config').get();
  }

  //  修改店铺昵称配置
  modifyShopNickConfig(
    shopNickConfigDto: ShopNickConfigDto
  ): Promise<ShopNickConfigDto> {
    return this.http
      .path('/modify-nick-config')
      .setRequestBody(shopNickConfigDto)
      .post();
  }

  //  修改个性昵称
  updatePersonalNick(
    command: SubAccountPersonalNickUpdateWebCmd
  ): Promise<ShopNickConfigDto> {
    return this.http
      .path('/sub-account/update-personal-nick')
      .setRequestBody(command)
      .post();
  }

  getPin(params: { buyerOpenUid: string }): Promise<string> {
    return this.http.path('/buyer/get-pin').setRequestBody(params).post();
  }

  getOpenDialogInfo(buyerOpenUid: string): Promise<OpenDialogInfoDto> {
    return this.http
      .path('/buyer/open-dialog-info')
      .setRequestBody({ buyerOpenUid })
      .post();
  }
}

export default new ShopRequest();
