import {
  ContentWithImageAndKeyword,
  ContentWithKeyword
} from '@/services/parse/type';
import { getPlatform } from '../platform';

const { platform } = getPlatform();

function transformExpressions(keyword: string): string {
  const regWords = Array.from('*.?+$^[](){}|/')
    .map(w => `\\${w}`)
    .join('|');
  return keyword.replace(new RegExp(regWords, 'g'), w => `\\${w}`);
}

function transToMatchRegs(keys: Array<string>): Array<string> {
  return keys
    .map(text => transformExpressions(text))
    .sort((a, b) => b.length - a.length);
}

const emojisTB: Record<string, string> = {
  '/:^_^': '001.gif',
  '/:^$^': '002.gif',
  '/:Q': '003.gif',
  '/:815': '004.gif',
  '/:809': '005.gif',
  '/:^O^': '006.gif',
  '/:081': '007.gif',
  '/:087': '008.gif',
  '/:086': '009.gif',
  '/:H': '010.gif',
  '/:012': '011.gif',
  '/:806': '012.gif',
  '/:b': '013.gif',
  '/:^x^': '014.gif',
  '/:814': '015.gif',
  '/:^W^': '016.gif',
  '/:080': '017.gif',
  '/:066': '018.gif',
  '/:807': '019.gif',
  '/:805': '020.gif',
  '/:071': '021.gif',
  '/:072': '022.gif',
  '/:065': '023.gif',
  '/:804': '024.gif',
  '/:813': '025.gif',
  '/:818': '026.gif',
  '/:015': '027.gif',
  '/:084': '028.gif',
  '/:801': '029.gif',
  '/:811': '030.gif',
  '/:?': '031.gif',
  '/:077': '032.gif',
  '/:083': '033.gif',
  '/:817': '034.gif',
  '/:!': '035.gif',
  '/:068': '036.gif',
  '/:079': '037.gif',
  '/:028': '038.gif',
  '/:026': '039.gif',
  '/:007': '040.gif',
  '/:816': '041.gif',
  '/:\'""': '042.gif',
  '/:802': '043.gif',
  '/:027': '044.gif',
  '/:(Zz...)': '045.gif',
  '/:*&*': '046.gif',
  '/:810': '047.gif',
  '/:>_<': '048.gif',
  '/:018': '049.gif',
  '/:>O<': '050.gif',
  '/:020': '051.gif',
  '/:044': '052.gif',
  '/:819': '053.gif',
  '/:085': '054.gif',
  '/:812': '055.gif',
  '/:"': '056.gif',
  // '/:>M<': '057.gif',
  '/:>@<': '057.gif',
  '/:076': '058.gif',
  '/:069': '059.gif',
  '/:O': '060.gif',
  '/:067': '061.gif',
  '/:043': '062.gif',
  '/:P': '063.gif',
  '/:808': '064.gif',
  '/:>W<': '065.gif',
  '/:073': '066.gif',
  '/:008': '067.gif',
  '/:803': '068.gif',
  '/:074': '069.gif',
  '/:O=O': '070.gif',
  '/:036': '071.gif',
  '/:039': '072.gif',
  '/:045': '073.gif',
  '/:046': '074.gif',
  '/:048': '075.gif',
  '/:047': '076.gif',
  '/:girl': '077.gif',
  '/:man': '078.gif',
  '/:052': '079.gif',
  '/:(OK)': '080.gif',
  '/:8*8': '081.gif',
  '/:)-(': '082.gif',
  '/:lip': '083.gif',
  '/:-F': '084.gif',
  '/:-W': '085.gif',
  '/:Y': '086.gif',
  '/:qp': '087.gif',
  '/:$': '088.gif',
  '/:%': '089.gif',
  '/:(&)': '090.gif',
  '/:@': '091.gif',
  '/:~B': '092.gif',
  '/:U*U': '093.gif',
  '/:clock': '094.gif',
  '/:R': '095.gif',
  '/:C': '096.gif',
  '/:plane': '097.gif',
  '/:075': '098.gif'
};
const emojisJD: Record<string, string> = {
  '#E-s01': 's01.png',
  '#E-s02': 's02.png',
  '#E-s03': 's03.png',
  '#E-s04': 's04.png',
  '#E-s05': 's05.png',
  '#E-s06': 's06.png',
  '#E-s07': 's07.png',
  '#E-s08': 's08.png',
  '#E-s09': 's09.png',
  '#E-s10': 's10.png',
  '#E-s11': 's11.png',
  '#E-s12': 's12.png',
  '#E-s13': 's13.png',
  '#E-s14': 's14.png',
  '#E-s15': 's15.png',
  '#E-s16': 's16.png',
  '#E-s17': 's17.png',
  '#E-s18': 's18.png',
  '#E-s19': 's19.png',
  '#E-s20': 's20.png',
  '#E-s21': 's21.png',
  '#E-s22': 's22.png',
  '#E-s23': 's23.png',
  '#E-s24': 's24.png',
  '#E-s25': 's25.png',
  '#E-s26': 's26.png',
  '#E-s27': 's27.png',
  '#E-s28': 's28.png',
  '#E-s29': 's29.png',
  '#E-s30': 's30.png',
  '#E-s31': 's31.png',
  '#E-s32': 's32.png',
  '#E-s33': 's33.png',
  '#E-s34': 's34.png',
  '#E-s35': 's35.png',
  '#E-s36': 's36.png',
  '#E-s37': 's37.png',
  '#E-s38': 's38.png',
  '#E-s39': 's39.png',
  '#E-s40': 's40.png',
  '#E-s41': 's41.png',
  '#E-s42': 's42.png',
  '#E-s43': 's43.png',
  '#E-s44': 's44.png',
  '#E-s45': 's45.png',
  '#E-s46': 's46.png',
  '#E-s47': 's47.png',
  '#E-s48': 's48.png',
  '#E-s49': 's49.png',
  '#E-s50': 's50.png',
  '#E-s51': 's51.png',
  '#E-s52': 's52.png',
  '#E-s53': 's53.png',
  '#E-s54': 's54.png',
  '#E-s55': 's55.png',
  '#E-s56': 's56.png',
  '#E-s57': 's57.png',
  '#E-s58': 's58.png',
  '#E-s59': 's59.png',
  '#E-s60': 's60.png',
  '#E-s61': 's61.png',
  '#E-s62': 's62.png',
  '#E-s63': 's63.png',
  '#E-s64': 's64.png',
  '#E-s65': 's65.png',
  '#E-s66': 's66.png',
  '#E-s67': 's67.png',
  '#E-s68': 's68.png',
  '#E-s69': 's69.png',
  '#E-s70': 's70.png',
  '#E-s71': 's71.png',
  '#E-s72': 's72.png'
};
const emojisDD: Record<string, string> = {
  '[微笑]': '1.00ffcb21.png',
  '[色]': '2.abc2a888.png',
  '[发呆]': '3.f04a1782.png',
  '[酷拽]': '4.126b158f.png',
  '[抠鼻]': '5.2f45f876.png',
  '[流泪]': '6.3abf4e7a.png',
  '[捂脸]': '7.979af83e.png',
  '[发怒]': '8.647d427f.png',
  '[呲牙]': '9.352535b9.png',
  '[一起加油]': '10.9230743e.png',
  '[害羞]': '11.a0b217aa.png',
  '[调皮]': '12.04852a97.png',
  '[晕]': '13.f00bff6a.png',
  '[衰]': '14.fb27b637.png',
  '[闭嘴]': '15.15c1afdb.png',
  '[机智]': '16.5cf6e652.png',
  '[赞]': '17.69b39103.png',
  '[鼓掌]': '18.a6eef8f9.png',
  '[感谢]': '19.9e697477.png',
  '[抱抱你]': '20.7f5164a7.png',
  '[来看我]': '21.9c63968d.png',
  '[灵机一动]': '22.88ca4143.png',
  '[耶]': '23.c30e1f2e.png',
  '[打脸]': '24.9a710b3c.png',
  '[大笑]': '25.06538693.png',
  '[哈欠]': '26.f926d6a7.png',
  '[震惊]': '27.8c179197.png',
  '[送心]': '28.2befa2a1.png',
  '[困]': '29.cb073fd5.png',
  '[疑问]': '30.120b0c0e.png',
  '[泣不成声]': '31.0dfe86c3.png',
  '[小鼓掌]': '32.d3d8b679.png',
  '[大金牙]': '33.c1f127c0.png',
  '[偷笑]': '34.8085731a.png',
  '[石化]': '35.04909dbd.png',
  '[思考]': '36.6add7794.png',
  '[吐血]': '37.dd6e1ef0.png',
  '[可怜]': '38.3dd45140.png',
  '[嘘]': '39.a1e843c6.png',
  '[撇嘴]': '40.faadcbf4.png',
  '[笑哭]': '41.10990ae9.png',
  '[奸笑]': '42.7351e888.png',
  '[得意]': '43.0a18e5f5.png',
  '[憨笑]': '44.33dacec9.png',
  '[坏笑]': '45.c295541f.png',
  '[抓狂]': '46.6c4e6145.png',
  '[泪奔]': '47.88f022e8.png',
  '[钱]': '48.9f554374.png',
  '[恐惧]': '49.54e5a5eb.png',
  '[愉快]': '50.f0f821ae.png',
  '[快哭了]': '51.035463f8.png',
  '[翻白眼]': '52.4ed840ed.png',
  '[互粉]': '53.61a6e827.png',
  '[我想静静]': '54.1f03baf9.png',
  '[委屈]': '55.b483de44.png',
  '[舔屏]': '56.7c8ab0e5.png',
  '[鄙视]': '57.9527d999.png',
  '[飞吻]': '58.b8b55558.png',
  '[再见]': '59.e3b3cd97.png',
  '[紫薇别走]': '60.37e9d228.png',
  '[听歌]': '61.a5d4dd5b.png',
  '[求抱抱]': '62.7d2271ea.png',
  '[绝望的凝视]': '63.a04d673e.png',
  '[不失礼貌的微笑]': '64.9970f9d1.png',
  '[不看]': '65.d54de48f.png',
  '[裂开]': '66.60234f77.png',
  '[干饭人]': '67.b350d84e.png',
  '[吐舌]': '68.b338c97d.png',
  '[呆无辜]': '69.e9fb9a5f.png',
  '[看]': '70.da02b4b8.png',
  '[白眼]': '71.d40391f5.png',
  '[熊吉]': '72.2d7e2e07.png',
  '[猪头]': '73.f177c927.png',
  '[冷漠]': '74.957c0fcd.png',
  '[微笑袋鼠]': '75.725419ce.png',
  '[凝视]': '76.611d2a5a.png',
  '[暗中观察]': '77.94f70e6e.png',
  '[二哈]': '78.fab862c0.png',
  '[菜狗]': '79.340d798f.png',
  '[黑脸]': '80.b086ba09.png',
  '[吃瓜群众]': '81.1dbfde9b.png',
  '[绿帽子]': '82.186990b7.png',
  '[摸头]': '83.92e9c51c.png',
  '[皱眉]': '84.6420181e.png',
  '[擦汗]': '85.5d7e7178.png',
  '[红脸]': '86.5fa4f1ef.png',
  '[尬笑]': '87.8faeeb77.png',
  '[做鬼脸]': '88.5bd64bb7.png',
  '[强]': '89.75f01166.png',
  '[如花]': '90.ffd20ec8.png',
  '[吐]': '91.348ec8c7.png',
  '[惊喜]': '92.74c93379.png',
  '[敲打]': '93.98793e14.png',
  '[奋斗]': '94.c76f40c7.png',
  '[吐彩虹]': '95.82c8e7f8.png',
  '[大哭]': '96.3827e26b.png',
  '[嘿哈]': '97.863b604c.png',
  '[加好友]': '98.0747ae36.png',
  '[惊恐]': '99.6c3df064.png',
  '[惊讶]': '100.81269261.png',
  '[囧]': '101.dc0ddd6c.png',
  '[难过]': '102.d57e02b2.png',
  '[斜眼]': '103.ef285677.png',
  '[阴险]': '104.51c9e363.png',
  '[悠闲]': '105.3b3a9b2c.png',
  '[咒骂]': '106.1e919106.png',
  '[比心]': '107.d0877405.png',
  '[强壮]': '108.f29d2382.png',
  '[碰拳]': '109.2c9c801b.png',
  '[ok]': '110.21c14892.png',
  '[击掌]': '111.b9ee4a98.png',
  '[左上]': '112.59f5929a.png',
  '[握手]': '113.325d02b2.png',
  '[抱拳]': '114.74d9dbbc.png',
  '[勾引]': '115.b250dfb3.png',
  '[拳头]': '116.866341b7.png',
  '[弱]': '117.406ab973.png',
  '[胜利]': '118.0efe18b4.png',
  '[右边]': '119.128e3a54.png',
  '[左边]': '120.26251bef.png',
  '[足球]': '121.6b13060b.png',
  '[球鞋]': '122.b4216a82.png',
  '[手套]': '123.8a8da089.png',
  '[嘴唇]': '124.76687aa3.png',
  '[爱心]': '125.eb19ade7.png',
  '[心碎]': '126.51359fb5.png',
  '[玫瑰]': '127.cbb23401.png',
  '[凋谢]': '128.f9f40254.png',
  '[啤酒]': '129.83616f99.png',
  '[咖啡]': '130.3a37a2b2.png',
  '[蛋糕]': '131.effb5788.png',
  '[礼物]': '132.35df2611.png',
  '[撒花]': '133.3d0e272c.png',
  '[V5]': '134.33a6d7c1.png',
  '[绝]': '135.a1b827c2.png',
  '[666]': '136.fca88149.png',
  '[给力]': '137.8ef577cd.png',
  '[红包]': '138.996a0d75.png',
  '[屎]': '139.37ad8b11.png',
  '[发]': '140.771f7a10.png',
  '[我太难了]': '141.5e46966c.png',
  '[18禁]': '142.b5a0efc3.png',
  '[炸弹]': '143.a35c18c5.png',
  '[去污粉]': '144.05fb11a4.png',
  '[西瓜]': '145.a4522d8c.png',
  '[加鸡腿]': '146.73a8798c.png',
  '[我酸了]': '147.b1207df1.png',
  '[握爪]': '148.865c3ac2.png',
  '[太阳]': '149.d35aa456.png',
  '[月亮]': '150.c4a7a912.png',
  '[给跪了]': '151.e5da84a7.png',
  '[蕉绿]': '152.54c8795d.png',
  '[扎心]': '153.5528b54b.png',
  '[胡瓜]': '154.4eba1812.png',
  '[yyds]': '155.0f0d3a21.png',
  '[emo]': '156.37b5ae89.png',
  '[打call]': '157.ecf0b09b.png',
  '[栓Q]': '158.0cac7864.png',
  '[无语]': '159.9d097eae.png',
  '[雪人]': '160.34d5c42f.png',
  '[雪花]': '161.ae79de6f.png',
  '[圣诞树]': '162.48f47984.png',
  '[平安果]': '163.506bb3ff.png',
  '[圣诞帽]': '164.7eb13042.png',
  '[气球]': '165.1d198d2c.png',
  '[干杯]': '166.2d234eed.png',
  '[烟花]': '167.d5e984ea.png',
  '[福]': '168.191ed446.png',
  '[candy]': '169.76d65039.png',
  '[糖葫芦]': '170.fa4b5761.png',
  '[虎头]': '171.8a32d4a7.png',
  '[饺子]': '172.feb043cb.png',
  '[鞭炮]': '173.cd52dc4c.png',
  '[元宝]': '174.d2d26f91.png',
  '[灯笼]': '175.a6f3eb07.png',
  '[锦鲤]': '176.32ff4aa9.png',
  '[巧克力]': '177.af64bc14.png',
  '[汤圆]': '178.42b32785.png',
  '[情书]': '179.4e0cdbe1.png',
  '[iloveyou]': '180.78eff54d.png',
  '[戒指]': '181.08b578e0.png'
};

const emojis = { TB: emojisTB, JD: emojisJD, DD: emojisDD }[platform];

const entries = Object.entries(emojis);

let entriesWithGif: Array<[string, string]> = [];

entriesWithGif = entries.map(([k, v]) => [
  k,
  require(`../../images/emoji/${platform}/${v}`) // eslint-disable-line
]);

export const emojiIndex = Object.fromEntries(entriesWithGif);

export const keys = Object.keys(emojiIndex);

const emojiKeyRegArray = transToMatchRegs(keys);

const emojiReg = emojiKeyRegArray.join('|');

export class ParseService {
  parseWithEmoji(message: string): Array<ContentWithImageAndKeyword> {
    if (!message) {
      return [{ content: '' }];
    }
    const emo = message.match(new RegExp(emojiReg, 'g'));
    if (!emo || !emo.length) {
      return [{ content: message }];
    }
    const parts = message.split(new RegExp(emojiReg, 'g'));

    return parts
      .map((content, index) => {
        const image = emo[index];
        return image
          ? [{ content }, { content: emojiIndex[image], image: true }]
          : [{ content }];
      })
      .reduce((r, c) => r.concat(c), []);
  }

  parseWithKeyword(
    message: string,
    keywords: string[]
  ): Array<ContentWithKeyword> {
    const keywordArray = transToMatchRegs(keywords);

    const keywordReg = new RegExp(keywordArray.join('|'), 'gu');

    const kds = message.match(keywordReg);
    if (!kds || !kds.length) {
      return [{ content: message }];
    }
    const parts = message.split(keywordReg);
    return parts
      .map((c, index) =>
        kds[index]
          ? [{ content: c }, { content: kds[index], keyword: true }]
          : [{ content: c }]
      )
      .reduce((r, c) => r.concat(c), []);
  }

  parseWithEmojiAndKeyword(
    message: string,
    keywords: string[]
  ): Array<ContentWithImageAndKeyword> {
    const keywordArray = transToMatchRegs(keywords);
    const keywordReg = new RegExp(
      emojiKeyRegArray.concat(keywordArray).join('|'),
      'gu'
    );
    const kds = message.match(keywordReg);
    if (!kds || !kds.length) {
      return [{ content: message }];
    }
    const parts = message.split(keywordReg);
    return parts
      .map((c, index) => {
        const matchKey = kds[index];
        if (matchKey === undefined) {
          return [{ content: c }];
        }
        const image = emojiIndex[matchKey];
        if (image) {
          return [{ content: c }, { content: image, image: true }];
        }
        return [{ content: c }, { content: matchKey, keyword: true }];
      })
      .reduce(
        (
          r: Array<ContentWithImageAndKeyword>,
          c: Array<ContentWithImageAndKeyword>
        ) => r.concat(c),
        []
      );
  }
}

export default new ParseService();
