import React, { memo, useRef } from 'react';
import { Checkbox, CheckboxGroup } from '@/components/widgets/checkbox';
import { Paragraph } from '@/components/widgets/typography';
import MiniImage from '@/components/works/miniImage';
import { approveStatusMap } from '../../constants';
import css from './style.less';
import { SkuWebDto } from '@/types/esItem';
import { combineSkuProperties } from '../../service';
import { VirtualDiv2 } from '@/components/layouts/virtualList';
import { cloneDeep } from 'lodash';

type SkuToolTipProps = {
  itemSkus: SkuWebDto[];
  selectSkus: SkuWebDto[];
  onSelect: (v: SkuWebDto, container?: HTMLElement | null) => void;
};

type SkuItem = {
  sku: SkuWebDto;
  checked: boolean;
} & Pick<SkuToolTipProps, 'onSelect'>;

const SkuItem = memo((props: SkuItem) => {
  const { sku, checked, onSelect } = props;

  return (
    <div key={sku.taobaoSkuId} className={css.sku}>
      <Checkbox
        checked={checked}
        value={sku.taobaoSkuId}
        onChange={e => onSelect(sku)}
      />
      <div className={css.imgBox}>
        <div style={{ position: 'relative' }}>
          <MiniImage
            src={sku.picUrl}
            size={50}
            style={{ border: '1px solid #85aeff' }}
            lazyLoad
          />
          <div className={css.approveStatusMask}>
            {approveStatusMap[sku.approveStatus]}
          </div>
        </div>
      </div>
      <Paragraph
        style={{ marginBottom: '4px', height: '41px' }}
        ellipsis={{
          rows: 2,
          tooltip: combineSkuProperties(sku.skuProperties)
        }}
      >
        {sku.title}
      </Paragraph>
      {/* <Paragraph ellipsis={{ rows: 1, tooltip: sku.taobaoSkuId }}>
    <span style={{ color: '#818181' }}>SKU编号：</span>
    {sku.taobaoSkuId}
  </Paragraph> */}
      <Paragraph ellipsis={{ rows: 1, tooltip: sku.price }}>
        <span style={{ color: '#818181' }}>SKU价格：</span>
        {sku.price}
      </Paragraph>
      {sku.outerId ? (
        <Paragraph ellipsis={{ rows: 1, tooltip: sku.outerId }}>
          <span style={{ color: '#818181' }}>商家SKU：</span>
          {sku.outerId}
        </Paragraph>
      ) : null}
    </div>
  );
});

export const SkuToolTip = memo((props: SkuToolTipProps) => {
  const { itemSkus, selectSkus, onSelect } = props;

  // 让重复校验弹窗出现的时候tooltip不会自动关掉
  const divRef = useRef<HTMLDivElement>(null);

  function toMultidimensional(arr, chunkSize) {
    return arr.reduce((acc, val, i) => {
      const chunkIndex = Math.floor(i / chunkSize);
      if (!acc[chunkIndex]) {
        acc[chunkIndex] = [];
      }
      acc[chunkIndex].push(val);
      return acc;
    }, []);
  }

  const newItemSkus = toMultidimensional(cloneDeep(itemSkus), 2);
  const selectedTaobaoSkuId = selectSkus.map(v => v.taobaoSkuId);

  return (
    <VirtualDiv2
      className={css.scroll}
      dataSource={newItemSkus || []}
      itemHeight={223}
      renderItem={(skus: SkuWebDto[]) => {
        return (
          <div
            ref={divRef}
            className={css.skuToolTip}
            onClick={e => e.stopPropagation()}
          >
            {skus.map((sku: SkuWebDto) => (
              <SkuItem
                sku={sku}
                checked={selectedTaobaoSkuId.includes(sku.taobaoSkuId)}
                onSelect={onSelect}
              />
            ))}
          </div>
        );
      }}
    />
  );
});
