import { Http, http } from '@/libs/http';
import { SkuWebDto, SpuItemDto } from '@/types/esItem';
import {
  EsItemCommand,
  BatchSelectDto,
  SkuSearchQuery,
  BatchSkuSelectDto
} from './type';

export class ChatpeerRequest {
  private http: Http = http('/api/shop/item');

  private http2: Http = http('/api/shop/sku');

  esItemList(
    query: EsItemCommand
  ): Promise<{ items: SpuItemDto[]; totalResult: number }> {
    return this.http.path('search-v2').setRequestParams(query).get();
  }

  batchFetchList(query: {
    searchTaobaoIids: string;
    selectedTaobaoIids: string[];
  }): Promise<BatchSelectDto> {
    return this.http.path('batch-check-taobaoiid').setRequestBody(query).post();
  }

  batchFetchSkuList(query: {
    searchTaobaoSkuIds: string;
    selectedTaobaoSkuIds: string[];
  }): Promise<BatchSkuSelectDto> {
    return this.http
      .path('batch-check-taobaoskuid')
      .setRequestBody(query)
      .post();
  }

  getByTaoBaoIIds(query: {
    searchTaobaoIids: string[];
  }): Promise<SpuItemDto[]> {
    return this.http.path('get-by-taobao-iids-v2').setRequestBody(query).post();
  }

  skusByTaobaoIid(taobaoIid: string): Promise<SkuWebDto[]> {
    return this.http
      .path('skus-by-taobao-iid')
      .setRequestBody({ taobaoIid })
      .post();
  }

  skuSearch(
    query: SkuSearchQuery
  ): Promise<{ items: SkuWebDto[]; totalResult: number }> {
    return this.http2.path('search').setRequestBody(query).post();
  }

  getByTaobaoSkuIds(query: { taobaoSkuIds: string[] }): Promise<SkuWebDto[]> {
    return this.http2
      .path('get-by-taobao-sku-ids')
      .setRequestBody(query)
      .post();
  }
}

export default new ChatpeerRequest();
