import React, { memo, useEffect } from 'react';
import { useAgentReducer } from 'use-agent-reducer';
import {
  TradeStatusEditor,
  TradeStatusList
} from '@/components/works/tradeStatusEditor';
import { Params } from '@/components/works/tradeStatusEditor/type';
import { useDialogMethods } from '@/components/widgets/modal/dialogs';
import { Spin } from '@/components/widgets/spin';
import { configsToRules } from '@/components/works/remindMainStrategyDialog/components/afterSale/util';
import { Badge } from '@/components/widgets/badge';
import { unitTextMap } from '@/constants/unitTime';
import { GeneralSettingModel } from './model';
import css from './style.less';
import {
  ConfigGroup,
  ConfigColunm,
  ConfigItem,
  HelpTooltip
} from './components';
import FatigueDialog from './fatigueDialog';
import { Params as FatigueDialogParams } from './fatigueDialog/type';
import { OfficeTimeDailog } from './officeTimeDailog';
import { OfficeTimeDailogProps } from './officeTimeDailog/type';
import { compareTime } from '@/utils/date';
import { ValidReplyDialog } from './validReplyDialog';
import { ValidReplyDialogProps } from './validReplyDialog/type';
import { TooltipWithIcon } from '@/components/works/tooltipWithIcon';
import ShopNick from './shopNick';
import { usePlatform } from '@/hooks/usePlatform';
import { DisplayWithPlatform } from '@/components/works/platformWrapper';

export default memo(() => {
  const { isJD } = usePlatform();
  const {
    state: {
      loading,
      afterSaleConfig,
      fatigueConfig,
      remindTypes,
      officeTimeConfig,
      validReplyConfig
    },
    fetchConfig,
    saveAfterSaleConfig,
    saveFatigue,
    saveOfficeTimeConfig,
    fetchValidReplyConfig
  } = useAgentReducer(GeneralSettingModel);

  const { afterSaleDay, statusConfigs } = afterSaleConfig;
  const { startTime, endTime, includeTypes } = officeTimeConfig;

  useEffect(() => {
    fetchConfig();
  }, []);

  const [openDialog] = useDialogMethods();

  const openTradeStatusEditor = () => {
    const params: Params = {
      tradeDay: afterSaleDay,
      tradeStatusRules: configsToRules(statusConfigs),
      timeoutTradeStatus: [],
      omitTradeStatus: ['WAIT_ORDER'],
      title: '设置售后期',
      showCheckAll: false,
      tradeDaySelectText: (
        <TooltipWithIcon
          label="，定义为售后期买家"
          tooltip="新用户初始最多获取3个月订单，随软件使用时间增长"
        />
      ),
      onOK: res => saveAfterSaleConfig(res)
    };
    openDialog(TradeStatusEditor, params);
  };

  const openFatigueDialog = () => {
    const params: FatigueDialogParams = {
      fatigueConfig,
      remindTypes,
      onOk: res => saveFatigue(res)
    };
    openDialog(FatigueDialog, params);
  };

  const openOfficeTimeDailog = () => {
    const params: OfficeTimeDailogProps = {
      officeTimeConfig,
      remindTypes: remindTypes.filter(i => i.supportOfficeTimeConfig),
      onOk: res => saveOfficeTimeConfig(res)
    };

    openDialog(OfficeTimeDailog, params);
  };

  const openValidReplyDialog = () => {
    const params: ValidReplyDialogProps = {
      onOk: fetchValidReplyConfig
    };

    openDialog(ValidReplyDialog, params);
  };

  const getTimePrefix = (startTime: string, endTime: string) => {
    if (isJD) {
      return '';
    }
    return compareTime(startTime, endTime) ? '当日' : '次日';
  };

  return (
    <Spin spinning={loading}>
      <DisplayWithPlatform displayPlatform={['JD', 'DD']}>
        <ShopNick />
      </DisplayWithPlatform>
      <ConfigGroup title="疲劳度控制">
        <ConfigColunm
          label={
            <span>
              疲劳度控制
              <HelpTooltip title="为智能跟单助手所有功能设置一个总发送次数上限，防止过度打扰，提升买家体验" />
              ：
              {fatigueConfig.active ? (
                <Badge text="已开启" status="success" />
              ) : (
                <Badge text="已关闭" status="default" />
              )}
            </span>
          }
          onEditClick={openFatigueDialog}
        >
          <ConfigItem label="规则">
            <span>
              同一买家{fatigueConfig.timeLimit.value}
              {unitTextMap[fatigueConfig.timeLimit.unit]}内，最多推送
              {fatigueConfig.maxTimes}次
            </span>
          </ConfigItem>
          <ConfigItem label="例外功能" flex>
            <span>
              {fatigueConfig.excludeTypes
                .map(
                  excludeType =>
                    remindTypes.find(
                      remindType => remindType.code === excludeType
                    )?.name ?? ''
                )
                .join('、') || '无'}
            </span>
          </ConfigItem>
        </ConfigColunm>
      </ConfigGroup>
      <ConfigGroup title="售后期配置">
        <ConfigColunm label="售后期" onEditClick={openTradeStatusEditor}>
          <ConfigItem label="售后状态" flex>
            <TradeStatusList rules={configsToRules(statusConfigs)} />
          </ConfigItem>
          <ConfigItem label="售后天数" flex>
            <div className={css.afterSaleDayWrapper}>
              <span className={css.afterSaleDay}>{afterSaleDay}天</span>
            </div>
          </ConfigItem>
        </ConfigColunm>
      </ConfigGroup>
      <ConfigGroup title="客服上班时间">
        <ConfigColunm label="客服上班时间" onEditClick={openOfficeTimeDailog}>
          <ConfigItem label="时间" flex>
            {`${startTime} - ${getTimePrefix(startTime, endTime)}${endTime}`}
          </ConfigItem>
          <ConfigItem label="补发功能" flex>
            {includeTypes
              .map(
                includeType =>
                  remindTypes.find(i => i.code === includeType)?.name ?? ''
              )
              .join('、') || '无'}
          </ConfigItem>
        </ConfigColunm>
      </ConfigGroup>
      <ConfigGroup title="有效回复统计时长">
        <ConfigColunm
          label="有效回复统计时长"
          onEditClick={openValidReplyDialog}
        >
          <ConfigItem label="有效回复" flex>
            {`买家${validReplyConfig.value}${
              unitTextMap[validReplyConfig.unit]
            }内回复，算作有效回复`}
          </ConfigItem>
        </ConfigColunm>
      </ConfigGroup>
    </Spin>
  );
});
