/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { ConfigGroup, ConfigColunm, ConfigItem } from '../components';
import shopRequest from '@/requests/shop';
import { uiPrompt } from '@/avatars';
import useRequest from '@/hooks/useRequest';
import { Icon } from '@/components/widgets/icon';
import css from './style.less';
import { Input } from '@/components/widgets/input';
import { ShopNickConfigDto } from '@/requests/shop/type';
import { Select } from '@/components/widgets/select';
import { NickType, nickTypeOptions } from '@/requests/shop/enum';
import { Button } from '@/components/widgets/button';
import { push } from '@/libs/history';
import { DisplayWithPlatform } from '@/components/works/platformWrapper';

const ShopNick: React.FC = () => {
  const { data, run: getConfig } = useRequest(
    () => shopRequest.acquireShopNickConfig(),
    {
      onError: uiPrompt.current.error
    }
  );

  const { sameNick, fwzsNick, nickType } = data || {};

  const [fwzsNickEdit, setFwzsNickEdit] = useState(false);
  const [fwzsNickInput, setFwzsNickInput] = useState<string>();
  const [fwzsNickError, setFwzsNickError] = useState<string>();

  const [sameNickEdit, setSameNickEdit] = useState(false);
  const [sameNickInput, setSameNickInput] = useState<string>();
  const [sameNickError, setSameNickError] = useState<string>();

  const { run: runSave } = useRequest(
    (v: ShopNickConfigDto) => shopRequest.modifyShopNickConfig(v),
    {
      onError: uiPrompt.current.error,
      manual: true,
      onSuccess: () => {
        uiPrompt.current.success('保存成功');
        getConfig();
        setFwzsNickEdit(false);
        setSameNickEdit(false);
      }
    }
  );

  const handleSave = (v: Partial<ShopNickConfigDto>) => {
    if (!data) {
      return;
    }

    runSave({ ...data, ...v });
  };

  const handleFwzsNickEditClick = () => {
    setFwzsNickEdit(true);
    setSameNickEdit(false);
    setFwzsNickInput(fwzsNick);
    setFwzsNickError('');
  };

  const handleFwzsNickEditSave = () => {
    if (!fwzsNickInput) {
      setFwzsNickError('请填写客服昵称');
      return;
    }
    if (fwzsNickInput.length > 10) {
      setFwzsNickError('客服昵称最多10个字');
      return;
    }
    handleSave({ fwzsNick: fwzsNickInput });
  };

  const handleSameNickEditClick = () => {
    setFwzsNickEdit(false);
    setSameNickEdit(true);
    setSameNickInput(sameNick);
    setSameNickError('');
  };

  const handleSameNickEditSave = () => {
    if (!sameNickInput) {
      setFwzsNickError('请填写客服昵称');
      return;
    }
    if (sameNickInput.length > 10) {
      setFwzsNickError('客服昵称最多10个字');
      return;
    }
    handleSave({ sameNick: sameNickInput });
  };

  return (
    <ConfigGroup title="客服昵称">
      <ConfigColunm label="客服昵称" contentClassName={css.content}>
        <DisplayWithPlatform displayPlatform={['TB', 'JD']}>
          <ConfigItem
            className={css.configItem}
            labelMaxWords={6}
            label="智能客服助手"
            flex
          >
            {fwzsNickEdit ? (
              <div style={{ position: 'relative' }}>
                <div className={css.flexCenter}>
                  <Input
                    className={fwzsNickError ? css.errorInput : ''}
                    showCount
                    maxLength={10}
                    onBlur={handleFwzsNickEditSave}
                    onFocus={() => setFwzsNickError('')}
                    value={fwzsNickInput}
                    onChange={v => {
                      setFwzsNickInput(v);
                      setFwzsNickError('');
                    }}
                  />
                  <DisplayWithPlatform displayPlatform={['JD']}>
                    <Icon
                      type="queding"
                      style={{ height: 18 }}
                      className={css.editIcon}
                      onClick={handleFwzsNickEditSave}
                    />
                  </DisplayWithPlatform>
                </div>
                {fwzsNickError ? (
                  <div className={css.errorMessage}>{fwzsNickError}</div>
                ) : null}
              </div>
            ) : (
              <>
                {fwzsNick}
                <DisplayWithPlatform displayPlatform={['JD']}>
                  <Icon
                    type="bianji"
                    style={{ height: 18 }}
                    className={css.editIcon}
                    onClick={handleFwzsNickEditClick}
                  />
                </DisplayWithPlatform>
              </>
            )}
          </ConfigItem>
        </DisplayWithPlatform>

        <ConfigItem className={css.configItem} label="人工客服" flex>
          <div className={css.flexCenter}>
            <Select
              style={{ marginRight: 16 }}
              options={nickTypeOptions}
              value={nickType}
              onChange={v => handleSave({ nickType: v })}
            />
            {nickType === NickType.PERSONAL ? (
              <Button
                type="link"
                onClick={() => push('/setting/sub-account-permissions')}
              >
                编辑 &gt;&gt;
              </Button>
            ) : null}
            {nickType === NickType.SAME ? (
              sameNickEdit ? (
                <div style={{ position: 'relative' }}>
                  <div className={css.flexCenter}>
                    <Input
                      className={sameNickError ? css.errorInput : ''}
                      showCount
                      maxLength={10}
                      onBlur={handleSameNickEditSave}
                      onFocus={() => setSameNickError('')}
                      value={sameNickInput}
                      onChange={v => {
                        setSameNickInput(v);
                        setSameNickError('');
                      }}
                    />

                    <Icon
                      type="queding"
                      style={{ height: 18 }}
                      className={css.editIcon}
                      onClick={handleSameNickEditSave}
                    />
                  </div>
                  {sameNickError ? (
                    <div className={css.errorMessage}>{sameNickError}</div>
                  ) : null}
                </div>
              ) : (
                <>
                  {sameNick}
                  <Icon
                    type="bianji"
                    style={{ height: 18 }}
                    className={css.editIcon}
                    onClick={handleSameNickEditClick}
                  />
                </>
              )
            ) : null}
          </div>
        </ConfigItem>
      </ConfigColunm>
    </ConfigGroup>
  );
};

export default ShopNick;
