import { SendMessageSpeed } from '@/requests/shop/type';
import { getPlatform } from '@/services/platform';

const { platform } = getPlatform();

export const speedTextMap: Record<SendMessageSpeed, string> = {
  FAST: '快',
  MIDDLE: '中',
  SLOW: '慢'
};

export const subAccountStatusMapTB = {
  NORMAL: '正常',
  FROZEN: '冻结',
  DELETED: '删除',
  PUNISH_FROZEN: '处罚冻结'
};
export const subAccountStatusMapDD = {
  NORMAL: '正常',
  FROZEN: '冻结'
};
export const subAccountStatusMap = {
  TB: subAccountStatusMapTB,
  JD: subAccountStatusMapTB,
  DD: subAccountStatusMapDD
}[platform];

export const trackOrderEnableOptions = [
  { label: '开启', value: true },
  { label: '关闭', value: false }
];
export const traceOrderSelfModifyAllowedOptions = [
  { label: '允许', value: true },
  { label: '不允许', value: false }
];

export const sendMessageSpeedOptions = Object.entries(speedTextMap).map(
  ([key, value]) => ({
    label: value,
    value: key
  })
);
