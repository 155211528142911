import axios, { AxiosRequestConfig } from 'axios';
import Api from '../api';
import { UploadFileOptions, UploadFileRes } from '../type';
import { getHttpCache, restConfig } from '@/libs/http';

class DDApi extends Api {
  async http({
    path,
    config
  }: {
    path: string;
    config?: AxiosRequestConfig;
  }): Promise<any> {
    const DDPath = path.replace('api', 'dd-api');

    const res = await axios(DDPath, { ...config });

    const { data } = res;

    return data;
  }

  authorize(code: string): Promise<any> {
    return this.http({
      path: '/api/authorize',
      config: {
        method: 'post',
        headers: {
          ...restConfig.headers
        },
        withCredentials: true,
        data: { code }
      }
    });
  }

  uploadFile(v: UploadFileOptions): Promise<UploadFileRes | undefined> {
    throw new Error('Method not implemented.');
  }

  async uploadImg(file: File): Promise<number> {
    const formData = new FormData();
    formData.append('file', file);
    const { token, shopId } = getHttpCache();
    const headers = token
      ? { token, ...restConfig.headers }
      : { ...restConfig.headers };

    const res = await this.http({
      path: '/api/shop/image/upload-image',
      config: {
        method: 'post',
        data: formData,
        headers,
        params: { shopId }
      }
    });

    if (!res || !res.data) {
      throw new Error('上传错误');
    }
    return res.data as number;
  }

  async downloadImg(imageId: number): Promise<Blob | undefined> {
    const { token, shopId } = getHttpCache();
    const headers = token
      ? { token, ...restConfig.headers }
      : { ...restConfig.headers };

    const res = await this.http({
      path: '/api/shop/image/find-by-image-id',
      config: {
        method: 'get',
        responseType: 'blob',
        headers,
        params: { shopId, imageId }
      }
    });

    if (!res) {
      throw new Error('下载错误');
    }

    return new Blob([res]);
  }
}

export default DDApi;
