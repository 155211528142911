import React, { Key, memo, useEffect, useState } from 'react';
import css from './style.less';
import { Tree } from '@/components/widgets/tree';
import { itemGroupRequest } from '@/requests/itemGroups';
import { DataNode } from '@/pages/itemGroup/type';
import { Empty } from '@/components/layouts/empty';
import { ItemGroupDto } from '@/requests/itemGroups/type';
import { Spin } from '@/components/widgets/spin';

type ItemGroupSelectProps = {
  itemGroupIds?: number[];
  onChange?: (itemGroupIds: number[], info?: any) => void;
};

const formatGropList = (gropList: ItemGroupDto[]): DataNode[] => {
  const originGropList = gropList;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const loop = (gropList: ItemGroupDto[]) => {
    return gropList.map(t => {
      return {
        key: t.id,
        title: t.groupName,
        itemNum: t.itemNum,
        children: originGropList.some(g => g.id === t.id)
          ? loop(t.children)
          : undefined
      };
    });
  };

  return loop(gropList);
};

export const ItemGroupSelector = memo((props: ItemGroupSelectProps) => {
  const { itemGroupIds, onChange } = props;
  const [loading, setLoading] = useState(false);
  const [treeData, setTreeData] = useState<DataNode[]>([]);

  const init = async () => {
    setLoading(true);
    const itemGroupList = await itemGroupRequest.list();
    const newItemGroupList = itemGroupList.flatMap(i =>
      i.children.length ? i : []
    );

    const groupTree = formatGropList(newItemGroupList);

    const newGroupTree = groupTree
      .map(i => ({
        ...i,
        count: i.children?.reduce((p, c) => p + (c?.itemNum || 0), 0)
      }))
      .filter(i => i.count);

    setTreeData(newGroupTree);
    setLoading(false);
  };
  const allParenteCids = Array.from(new Set(treeData.map(i => i.key)));

  useEffect(() => {
    init();
  }, []);

  const handleCheck = (
    checked: Key[] | { checked: Key[]; halfChecked: Key[] },
    info
  ) => {
    const checkedWithoutParentCids = (checked as Key[]).filter(
      i => !allParenteCids.includes(i)
    );
    onChange?.(checkedWithoutParentCids as number[], info);
  };

  return (
    <Spin spinning={loading}>
      <div className={css.treeHeader}>全部商品组</div>
      <div className={css.treeContent}>
        {treeData.length ? (
          <Tree
            checkedKeys={itemGroupIds}
            defaultExpandAll
            autoExpandParent
            defaultExpandParent
            checkable
            treeData={treeData}
            titleRender={(v: DataNode & { itemNum?: number }) => (
              <span>
                {v.title}
                {v.itemNum ? (
                  <span>
                    （<span style={{ color: 'orange' }}>{v.itemNum}</span>）
                  </span>
                ) : null}
              </span>
            )}
            onCheck={handleCheck}
          />
        ) : (
          <Empty />
        )}
      </div>
    </Spin>
  );
});
