import React, { memo, useState } from 'react';
import classNames from 'classnames';
import { SkuWebDto } from '@/types/esItem';
import css from './style.less';
import { approveStatusMap } from '../../constants';
import MiniImage from '@/components/works/miniImage';
import { Paragraph } from '@/components/widgets/typography';
import { Tooltip } from '@/components/widgets/tooltip';
import { Checkbox } from '@/components/widgets/checkbox';
import { copy } from '@/utils/dxUtils';
import { openGoodsDetail } from '@/services/openGoodsDetail';

type GoodsItemProps = {
  type?: 'wait' | 'selected';
  selected?: boolean;
  item: SkuWebDto;
  onSelect(item: SkuWebDto): void;
};

export const SkuItem = memo((props: GoodsItemProps) => {
  const { selected, onSelect, item, type } = props;
  const { picUrl, title, taobaoSkuId, approveStatus, price, outerId } = item;

  const toJdDetail = (e: React.MouseEvent | undefined) => {
    e?.stopPropagation();
    openGoodsDetail(taobaoSkuId);
  };

  const [hover, setHover] = useState(true);

  const handleClick = () => {
    onSelect(item);
  };

  return (
    <div
      className={classNames(css.option, selected ? css.selected : null)}
      onClick={handleClick}
      onMouseEnter={() => setHover(false)}
      onMouseLeave={() => setHover(true)}
      style={selected && !hover ? { background: '#FFF2F2' } : {}}
    >
      <div className={css.imgBox}>
        <MiniImage
          lazyLoad
          src={picUrl}
          size={48}
          className={css.img}
          onClick={toJdDetail}
        />
        <div className={css.approveStatusMask}>
          {approveStatusMap[approveStatus]}
        </div>
      </div>
      <div className={css.texts}>
        <Paragraph
          className={classNames(css.name, css.nameLink)}
          ellipsis={{ rows: 2, tooltip: title }}
          onClick={toJdDetail}
        >
          {title}
        </Paragraph>
        <div className={css.code}>
          <Tooltip title={`SKU编号：${taobaoSkuId}`}>
            <span
              className={css.iid}
              onClick={e => {
                e.stopPropagation();
                copy(taobaoSkuId);
              }}
            >
              SKU编号
            </span>
          </Tooltip>
          <span>￥{(price + 0.0).toFixed(2)}</span>
          {outerId ? (
            <Paragraph
              style={{ color: '#8c8c8c', flexGrow: '1' }}
              ellipsis={{ rows: 1, tooltip: outerId }}
            >
              {`商家SKU ${outerId}`}
            </Paragraph>
          ) : null}
        </div>
      </div>
      <Checkbox style={{ padding: '0px 16px 0px 12px' }} checked={selected} />
    </div>
  );
});
