import { getPlatform } from './platform';

export const openGoodsDetail = goodsId => {
  const { platform } = getPlatform();

  switch (platform) {
    case 'TB':
      window.open(`https://item.taobao.com/item.htm?id=${goodsId}`);
      break;
    case 'JD':
      window.open(
        `https://item.jd.com/${goodsId}.html?sdx=ehi-lLxFuZiE6JnJYYNUisImtDeZCQ0rsmpIsKxAYNqPPe_RLJxf5H_toUrjVWST`
      );
      break;

    case 'DD':
      window.open(
        `https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=${goodsId}&origin_type=604`
      );
      break;

    default:
      break;
  }
};
