/* eslint-disable react/jsx-boolean-value */
import React, { memo, useEffect, useState } from 'react';
import { RadioChangeEvent } from 'antd';
import { useAgentReducer, useAgentSelector } from 'use-agent-reducer';
import { DownOutlined } from '@ant-design/icons';
import { SubAccountPermissionsModel } from './model';
import css from './style.less';
import {
  WangwangGroupNamesProps,
  TrackOrderEnableProps,
  TraceOrderSelfModifyAllowedProps,
  BatchOperationProps,
  SendMessageSpeedOperationProps,
  SendMessageSpeedBatchOperationProps
} from './type';
import { Table, Column } from '@/components/widgets/table';
import { FormLike } from '@/components/widgets/formLike';
import ConditionUnit from '@/components/layouts/conditionUnit';
import { Select, Option } from '@/components/widgets/select';
import { Input } from '@/components/widgets/input';
import { Button } from '@/components/widgets/button';
import { Space } from '@/components/widgets/space';
import { TooltipWithIcon } from '@/components/works/tooltipWithIcon';
import { Switch } from '@/components/widgets/switch';
import { Radio, RadioGroup } from '@/components/widgets/radio';
import { useColumnDropdown } from './hook';
import { useDataSourceSorter } from '@/hooks/useSorter';
import { speedTextMap, subAccountStatusMap } from './constants';
import AutoTooltipText from '@/components/widgets/autoTooltipText';
import {
  AccountListDto,
  SubAccountWebDto,
  SendMessageSpeed,
  SubAccountTrackOrderLimitDto,
  SubAccountPermissionBatchUpdateResult,
  SubAccountPersonalNickUpdateWebCmd
} from '@/requests/shop/type';
import { wwGroupListRef } from '@/modules/wwGroupList';
import { useDialogMethods } from '@/components/widgets/modal/dialogs';
import { BatchDialog } from './batchDialog';
import { TipDialog } from './tipDialog';
import { Icon } from '@/components/widgets/icon';
import shopRequest from '@/requests/shop';
import { uiPrompt } from '@/avatars';
import useRequest from '@/hooks/useRequest/src/useRequest';
import { usePlatform } from '@/hooks/usePlatform';
import { getAccountLabel } from '@/hooks/useCustomerNames';
import { DisplayWithPlatform } from '@/components/works/platformWrapper';

const WangwangGroupNames = (props: WangwangGroupNamesProps) => {
  const { wangwangGroupNames } = props;

  if (!wangwangGroupNames) {
    return <span>-</span>;
  }

  return (
    <AutoTooltipText className={css.wangwangGroupNames}>
      {wangwangGroupNames.join('、')}
    </AutoTooltipText>
  );
};

const TrackOrderEnable = (props: TrackOrderEnableProps) => {
  const { subAccount, onChange } = props;
  const { trackOrderEnable, id } = subAccount;

  const handleChange = (v: boolean) => {
    onChange(id, v);
  };

  return (
    <Switch
      checkedChildren="开启"
      unCheckedChildren="关闭"
      checked={trackOrderEnable}
      onChange={handleChange}
    />
  );
};

const TraceOrderSelfModifyAllowed = (
  props: TraceOrderSelfModifyAllowedProps
) => {
  const { subAccount, onChange } = props;
  const { traceOrderSelfModifyAllowed, id } = subAccount;

  const handleChange = (e: RadioChangeEvent) => {
    onChange(id, e.target.value);
  };

  return (
    <RadioGroup value={traceOrderSelfModifyAllowed} onChange={handleChange}>
      <Radio value={true}>允许</Radio>
      <Radio value={false}>不允许</Radio>
    </RadioGroup>
  );
};

const SendMessageSpeedOperation = (props: SendMessageSpeedOperationProps) => {
  const { subAccount, onChange } = props;
  const { sendMessageSpeed, id } = subAccount;

  const handleChange = (e: RadioChangeEvent) => {
    onChange(id, e.target.value);
  };

  return (
    <RadioGroup value={sendMessageSpeed} onChange={handleChange}>
      {Object.entries(speedTextMap).map(([value, label]) => (
        <Radio key={value} value={value}>
          {label}
        </Radio>
      ))}
    </RadioGroup>
  );
};

const BatchOperation = (props: BatchOperationProps) => {
  const { openText, closeText, onSelect, ...rest } = props;

  return (
    <div {...rest}>
      <div className={css.bactchItem} onClick={() => onSelect(true)}>
        {openText}
      </div>
      <div className={css.bactchItem} onClick={() => onSelect(false)}>
        {closeText}
      </div>
    </div>
  );
};

const SendMessageSpeedBatchOperation = (
  props: SendMessageSpeedBatchOperationProps
) => {
  const { onSelect, ...rest } = props;

  return (
    <div {...rest}>
      {Object.entries(speedTextMap).map(([value, label]) => (
        <div
          className={css.bactchItem}
          onClick={() => onSelect(value as SendMessageSpeed)}
        >
          批量设置{label}
        </div>
      ))}
    </div>
  );
};

export const RenderSubAccountItem = (
  props: SubAccountWebDto | AccountListDto
): React.ReactElement => {
  const { status } = props;

  return (
    <div>
      {getAccountLabel(props)}
      {status !== 'NORMAL' ? (
        <span style={{ color: '#ff8600' }}>
          （{subAccountStatusMap[status]}）
        </span>
      ) : null}
    </div>
  );
};

const RenderPersonNick = (props: {
  value: SubAccountWebDto;
  update: (v: SubAccountPersonalNickUpdateWebCmd) => void;
}): React.ReactElement => {
  const {
    value: { personalNick, id, subNick },
    update
  } = props;
  const displayValue = personalNick || subNick;
  const [edit, setEdit] = useState(false);
  const [editValue, setEditValue] = useState<string>();

  const handleBlur = () => {
    const currentVal = (editValue || '').trim();
    if (!currentVal || currentVal === displayValue) {
      setEdit(false);
      return;
    }
    update({ subAccountId: id, personalNick: currentVal });
    setEdit(false);
  };

  if (edit) {
    return (
      <span className={css.input}>
        <Input
          className={css.personalNickInput}
          value={editValue}
          onChange={setEditValue}
          autoFocus
          onBlur={handleBlur}
          onKeyUp={e => (e.key === 'Enter' ? handleBlur() : null)}
          placeholder="请输入客服个性昵称"
          maxLength={18}
          showCount
        />
      </span>
    );
  }

  return (
    <div
      className={`${css.personalNick} personalNick`}
      onClick={() => {
        setEdit(true);
        setEditValue(displayValue);
      }}
    >
      {displayValue}
      <Icon className={`${css.editIcon} editIcon`} type="bianji" />
    </div>
  );
};

const RemindSubAccountNumTip = memo(
  ({
    subAccountTrackOrderInfo
  }: {
    subAccountTrackOrderInfo?: SubAccountTrackOrderLimitDto;
  }) => {
    const { openTrackOrderNum, limitNum, nextValidLimitInfo } =
      subAccountTrackOrderInfo || {};
    const { limitNum: nextLimitNum, startDay } = nextValidLimitInfo || {};

    return (
      <div className={css.remindSubAccountNumTip}>
        <span>
          已开启跟单账号数：
          <span className={css.orange}>{openTrackOrderNum}个</span>
        </span>
        <span>
          当前跟单账号数量上限：
          <span className={css.orange}>
            {limitNum === -1 ? '不限' : `${limitNum}个`}
          </span>
        </span>
        {nextValidLimitInfo ? (
          <span className={css.gray}>
            从{startDay}开始账号数量上限为
            {nextLimitNum === -1 ? '不限' : nextLimitNum}
          </span>
        ) : null}
      </div>
    );
  }
);

export default memo(() => {
  const { isJD, isTB } = usePlatform();
  const {
    state: {
      subAccountListQueryDisplay,
      subAccountTrackOrderInfo,
      subAccountList,
      loading,
      page,
      pageSize,
      subAccountIds
    },
    fetchSubAccountList,
    handleSubAccountListQueryChange,
    handleSubAccountListQueryReset,
    fetchSubAccountTrackOrderInfo,
    handlePaginationChange,
    handleTrackOrderEnableChange,
    handleTraceOrderSelfModifyAllowedChange,
    handleSubAccountSelect,
    handleTrackOrderEnableBatchChange,
    handleTraceOrderSelfModifyAllowedBatchChange,
    handleSubAccountListQuerySearch,
    handleSendMessageSpeedChange,
    handleSendMessageSpeedBatchChange
  } = useAgentReducer(SubAccountPermissionsModel);

  const { run: updatePersonNick } = useRequest(
    (v: SubAccountPersonalNickUpdateWebCmd) =>
      shopRequest.updatePersonalNick(v),
    {
      manual: true,
      onSuccess: () => {
        fetchSubAccountList();
        uiPrompt.current.success('保存成功');
      },
      onError: () => {
        uiPrompt.current.error('保存失败');
      }
    }
  );

  const [openDialog] = useDialogMethods<
    SubAccountPermissionBatchUpdateResult | undefined
  >();

  const wwGroupList = useAgentSelector(
    wwGroupListRef.current,
    s => s.wwGroupList
  );

  useEffect(() => {
    fetchSubAccountList();
    fetchSubAccountTrackOrderInfo();
  }, []);

  const traceOrderEnableDropdown = useColumnDropdown();
  const traceOrderSelfModifyAllowedDropdown = useColumnDropdown();
  const sendMessageSpeedDropdown = useColumnDropdown();

  const { dataSource, handleSorterChange } = useDataSourceSorter(
    subAccountList,
    { field: 'account', order: 'descend' }
  );

  const openBatchDialog = async () => {
    const res = await openDialog(BatchDialog);

    if (!res) return;

    const { successCount, notExistSubAccounts } = res;

    if (successCount) {
      fetchSubAccountList();
    }

    if (notExistSubAccounts.length) {
      openDialog(TipDialog, { successCount, notExistSubAccounts });
    }
  };

  return (
    <div className={css.main}>
      <FormLike
        value={subAccountListQueryDisplay}
        onChange={handleSubAccountListQueryChange}
      >
        <Space className={css.form} direction="horizontal">
          <ConditionUnit
            className={css.formItem}
            name="wangwangGroupId"
            label="客服组"
          >
            <Select className={css.select}>
              <Option value={null}>全部</Option>
              {wwGroupList.map(group => (
                <Option key={group.id} value={group.id}>
                  {group.groupName}
                </Option>
              ))}
            </Select>
          </ConditionUnit>
          <DisplayWithPlatform displayPlatform={['TB']}>
            <ConditionUnit
              className={css.formItem}
              name="sendMessageSpeed"
              label="发送速度"
            >
              <Select className={css.select}>
                <Option value={null}>全部</Option>
                {Object.entries(speedTextMap).map(([value, label]) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </ConditionUnit>
          </DisplayWithPlatform>
          <ConditionUnit
            className={css.formItem}
            name="subAccountStatus"
            label="子账号状态"
          >
            <Select className={css.select}>
              <Option value={null}>全部</Option>
              {Object.entries(subAccountStatusMap).map(([value, label]) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </ConditionUnit>
          <ConditionUnit
            className={css.formItem}
            name="subAccountName"
            label="子账号"
          >
            <Input className={css.input} />
          </ConditionUnit>
          <div className={css.formItem}>
            <Button type="primary" onClick={handleSubAccountListQuerySearch}>
              搜索
            </Button>
            <Button onClick={handleSubAccountListQueryReset}>重置</Button>
          </div>
        </Space>
      </FormLike>

      <div className={css.actions}>
        <RemindSubAccountNumTip
          subAccountTrackOrderInfo={subAccountTrackOrderInfo}
        />
        <Button type="primary" onClick={openBatchDialog}>
          批量操作
        </Button>
      </div>

      <Table
        className={css.table}
        bordered
        size="small"
        rowKey="id"
        dataSource={dataSource}
        loading={loading}
        showSorterTooltip={false}
        pagination={{
          size: 'default',
          position: ['bottomCenter'],
          showSizeChanger: true,
          current: page,
          pageSize,
          onChange: handlePaginationChange
        }}
        rowSelection={{
          type: 'checkbox',
          onChange: handleSubAccountSelect,
          selectedRowKeys: subAccountIds
        }}
        onChange={handleSorterChange}
      >
        <Column
          title="子账号"
          dataIndex="account"
          width="200px"
          sorter
          defaultSortOrder="descend"
          render={(account, record: SubAccountWebDto) => (
            <RenderSubAccountItem {...record} />
          )}
        />
        {isTB ? null : (
          <Column
            title="个性昵称"
            dataIndex="personalNick"
            width="200px"
            sorter
            defaultSortOrder="descend"
            render={(v, record: SubAccountWebDto) => (
              <RenderPersonNick value={record} update={updatePersonNick} />
            )}
          />
        )}
        <Column
          title={
            <TooltipWithIcon
              label="所属客服组"
              tooltip="子账号所在的店铺组，若无，请在对应店铺客服组管理处进行配置"
            />
          }
          dataIndex="wangwangGroupNames"
          width="120px"
          render={v => <WangwangGroupNames wangwangGroupNames={v} />}
        />
        <Column
          width="180px"
          className={css.filterColunm}
          title={
            <TooltipWithIcon
              label="跟单开关"
              tooltip="开启开关后，该子账号才可用于发送跟单"
            />
          }
          dataIndex="trackOrderEnable"
          sorter
          render={(v, record: SubAccountWebDto) => (
            <TrackOrderEnable
              subAccount={record}
              onChange={handleTrackOrderEnableChange}
            />
          )}
          filterDropdown={() => (
            <BatchOperation
              {...traceOrderEnableDropdown.menuProps}
              openText="批量开启"
              closeText="批量关闭"
              onSelect={value => {
                traceOrderEnableDropdown.close();
                handleTrackOrderEnableBatchChange(value);
              }}
            />
          )}
          filterIcon={() => (
            <Button type="link" {...traceOrderEnableDropdown.triggerProps}>
              批量操作
              <DownOutlined className={css.filterIcon} />
            </Button>
          )}
          filterDropdownVisible={traceOrderEnableDropdown.visible}
        />
        {isJD ? null : (
          <Column
            width="300px"
            className={css.filterColunm}
            title={
              <TooltipWithIcon
                label="是否允许客服自己开启/关闭跟单开关"
                tooltip="控制是否允许客服在客户端操作开关状态"
              />
            }
            dataIndex="traceOrderSelfModifyAllowed"
            sorter
            render={(v, record: SubAccountWebDto) => (
              <TraceOrderSelfModifyAllowed
                subAccount={record}
                onChange={handleTraceOrderSelfModifyAllowedChange}
              />
            )}
            filterDropdown={() => (
              <BatchOperation
                {...traceOrderSelfModifyAllowedDropdown.menuProps}
                openText="批量允许"
                closeText="批量不允许"
                onSelect={value => {
                  traceOrderSelfModifyAllowedDropdown.close();
                  handleTraceOrderSelfModifyAllowedBatchChange(value);
                }}
              />
            )}
            filterIcon={() => (
              <Button
                type="link"
                {...traceOrderSelfModifyAllowedDropdown.triggerProps}
              >
                批量操作
                <DownOutlined className={css.filterIcon} />
              </Button>
            )}
            filterDropdownVisible={traceOrderSelfModifyAllowedDropdown.visible}
          />
        )}
        {isTB ? (
          <Column
            width="200px"
            className={css.filterColunm}
            title={
              <TooltipWithIcon
                label="发送速度"
                tooltip={
                  <div>
                    <div>快：大约1.5秒/条（1分钟40条，1小时2400条）</div>
                    <div>中：大约2秒/条（1分钟30条，1小时1800条）</div>
                    <div>慢：大约3秒/条（1分钟20条，1小时1200条）</div>
                  </div>
                }
              />
            }
            dataIndex="traceOrderSelfModifyAllowed"
            render={(v, record: SubAccountWebDto) => (
              <SendMessageSpeedOperation
                subAccount={record}
                onChange={handleSendMessageSpeedChange}
              />
            )}
            filterDropdown={() => (
              <SendMessageSpeedBatchOperation
                {...sendMessageSpeedDropdown.menuProps}
                onSelect={value => {
                  sendMessageSpeedDropdown.close();
                  handleSendMessageSpeedBatchChange(value);
                }}
              />
            )}
            filterIcon={() => (
              <Button type="link" {...sendMessageSpeedDropdown.triggerProps}>
                批量操作
                <DownOutlined className={css.filterIcon} />
              </Button>
            )}
            filterDropdownVisible={sendMessageSpeedDropdown.visible}
          />
        ) : null}
      </Table>
    </div>
  );
});
