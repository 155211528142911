import { getPlatform } from '@/services/platform';
import { cloneDeep } from 'lodash';

const huaDong = [
  '上海',
  '江苏省',
  '浙江省',
  '安徽省',
  '福建省',
  '江西省',
  '山东省'
];
const ddhuaDong = [
  '上海市',
  '江苏省',
  '浙江省',
  '安徽省',
  '福建省',
  '江西省',
  '山东省'
];
const huaBei = ['北京', '天津', '山西省', '河北省', '内蒙古自治区'];
const ddHuaBei = ['北京市', '天津市', '山西省', '河北省', '内蒙古自治区'];
const huaZhong = ['湖南省', '湖北省', '河南省'];
const huaNan = ['广东省', '海南省', '广西壮族自治区'];
const dongBei = ['辽宁省', '吉林省', '黑龙江省'];
const xiBei = [
  '陕西省',
  '甘肃省',
  '青海省',
  '新疆维吾尔自治区',
  '宁夏回族自治区'
];
const xiNan = ['重庆', '云南省', '贵州省', '四川省', '西藏自治区'];
const ddXiNan = ['重庆市', '云南省', '贵州省', '四川省', '西藏自治区'];
const gangAoTai = ['香港特别行政区', '澳门特别行政区', '台湾'];
const jdGangAoTai = ['港澳', '台湾'];
const ddGangAoTai = ['香港特别行政区', '澳门特别行政区', '台湾省'];

export const areaMap = new Map([
  ['huaDong', '华东'],
  ['huaBei', '华北'],
  ['huaZhong', '华中'],
  ['huaNan', '华南'],
  ['dongBei', '东北'],
  ['xiBei', '西北'],
  ['xiNan', '西南'],
  ['gangAoTai', '港澳台']
]);

const areaProvincesMap = {
  huaDong,
  huaBei,
  huaZhong,
  huaNan,
  dongBei,
  xiBei,
  xiNan,
  gangAoTai
};

export const getAreaProvincesMap = () => {
  const { platform } = getPlatform();

  if (platform === 'TB') {
    return areaProvincesMap;
  }

  if (platform === 'DD') {
    return cloneDeep({
      ...areaProvincesMap,
      huaDong: ddhuaDong,
      gangAoTai: ddGangAoTai,
      huaBei: ddHuaBei,
      xiNan: ddXiNan
    });
  }

  const removeProvinceSuffix = province => {
    return province.replace(
      /省|自治区|特别行政区|壮族自治区|维吾尔自治区|回族自治区/g,
      ''
    );
  };

  const cleanedAreaProvincesMap = cloneDeep({
    ...areaProvincesMap,
    gangAoTai: jdGangAoTai
  });
  Object.keys(cleanedAreaProvincesMap).forEach(region => {
    cleanedAreaProvincesMap[region] =
      cleanedAreaProvincesMap[region].map(removeProvinceSuffix);
  });

  return cleanedAreaProvincesMap;
};
