import { Platform } from '@/types/platform';
import { getItem } from '@/utils/storage';

export const getPlatform = () => {
  const platform = (getItem('platform') || 'TB') as Platform;

  return {
    platform,
    isTB: platform === 'TB',
    isJD: platform === 'JD',
    isDD: platform === 'DD'
  };
};
